import Caracteristica1 from "../../../../src/sections/caracteristicas/caracteristica1.mdx";
import Caracteristica2 from "../../../../src/sections/caracteristicas/caracteristica2.mdx";
import Caracteristica3 from "../../../../src/sections/caracteristicas/caracteristica3.mdx";
import Caracteristica4 from "../../../../src/sections/caracteristicas/caracteristica4.mdx";
import Caracteristica5 from "../../../../src/sections/caracteristicas/caracteristica5.mdx";
import Caracteristica6 from "../../../../src/sections/caracteristicas/caracteristica6.mdx";
import * as React from 'react';
export default {
  Caracteristica1,
  Caracteristica2,
  Caracteristica3,
  Caracteristica4,
  Caracteristica5,
  Caracteristica6,
  React
};
import Cabecalho from "../../../../src/sections/cabecalho/cabecalho.mdx";
import Caracteristicas from "../../../../src/sections/caracteristicas/caracteristicas.mdx";
import QuemSomos from "../../../../src/sections/quemsomos/quemsomos.mdx";
import Geral from "../../../../src/sections/geral/geral.mdx";
import Rodape from "../../../../src/sections/rodape/rodape.mdx";
import * as React from 'react';
export default {
  Cabecalho,
  Caracteristicas,
  QuemSomos,
  Geral,
  Rodape,
  React
};
import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "images": ["geral1.jpg"]
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ContainerWrapper = makeShortcode("ContainerWrapper");
const ScrollAnimation = makeShortcode("ScrollAnimation");
const Div = makeShortcode("Div");
const ButtonTheme = makeShortcode("ButtonTheme");
const ImgGatsby = makeShortcode("ImgGatsby");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <ContainerWrapper sx={{
      background: theme => theme.colors['backgroundGeneral'] || theme.colors['background']
    }} mdxType="ContainerWrapper">
      <ScrollAnimation animateIn="fadeInRight" mdxType="ScrollAnimation">
  <Div sx={{
          display: 'flex',
          justifyContent: 'center',
          flexWrap: 'wrap',
          padding: ['0', '0', '24px']
        }} mdxType="Div">
    <Div sx={{
            marginTop: '3%'
          }} mdxType="Div">
      <h3>General Section</h3>
      <p> Example of generic section with IMG </p>
      <p> Example of Button theme with specific themes <ButtonTheme themes={['bulma', 'deep']} mdxType="ButtonTheme"> Toggle </ButtonTheme> </p>
     
    </Div>
    <Div sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center'
          }} mdxType="Div">
      <ImgGatsby fluid={props.imagesFluid['geral1.jpg']} sx={{
              width: '100%',
              maxWidth: '300px',
              borderRadius: '20px',
              height: 'auto',
              boxShadow: theme => theme.colors.imgShadow
            }} mdxType="ImgGatsby" />
    </Div>
  </Div>
  </ScrollAnimation>
    </ContainerWrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
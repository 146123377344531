import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "imagesFluid": ["imagem2.png", "imagem1.jpg"]
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const BgImage = makeShortcode("BgImage");
const Div = makeShortcode("Div");
const ImgGatsby = makeShortcode("ImgGatsby");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <BgImage Tag="div" sx={{
      mt: 5,
      height: '86vh',
      width: '100%',
      backgroundAttachment: 'fixed',
      zIndex: '1'
    }} fluid={props.imagesFluid['imagem1.jpg']} backgroundColor={props.context.theme.colors.backgroundHeader} mdxType="BgImage">
  <Div sx={{
        p: 4
      }} mdxType="Div">
    <h1>Title Header</h1>
  </Div>
  
  <ImgGatsby fluid={props.imagesFluid['imagem2.png']} sx={{
        position: 'absolute !important',
        bottom: '0px',
        width: '100%',
        height: '86vh'
      }} mdxType="ImgGatsby" />
    </BgImage>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
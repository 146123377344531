import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "imagesFluid": "caracteristica2.jpg",
  "title": "Servico 2"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const FeatureLayout = makeShortcode("FeatureLayout");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <FeatureLayout width={'33%'} featureImageFluid={props.imagesFluid['caracteristica2.png']} mdxType="FeatureLayout">
 <h2>Just in time</h2>
      <p>{`O sistema Just in time, simplifica e reduz as necessidades de ordem de serviços e de estoque, garantindo um maior espaço físico, e diminuição do prazo de entrega, além de melhores serviços oferecidos. Este é o conceito do método Just in time, uma estratégia que garante a entrega de produtos em sua hora exata, sem excessos, com volumes de entregas atrelados, visando suprir as necessidades produtivas, o que torna o processo mais assertivo e econômico.`}</p>
    </FeatureLayout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
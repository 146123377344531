import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "section": "footer"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Div = makeShortcode("Div");
const ScrollAnimation = makeShortcode("ScrollAnimation");
const SocialLink = makeShortcode("SocialLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Div sx={{
      background: theme => theme.colors['backgroundFooter']
    }} mdxType="Div">
      <ScrollAnimation animateIn="fadeIn" mdxType="ScrollAnimation">
        <Div sx={{
          display: "flex",
          justifyContent: "space-around",
          flexWrap: 'wrap',
          flexDirection: ['column', 'column', 'row'],
          px: 4
        }} mdxType="Div">
          <div>
            <h4> Contate-nos </h4>
            <h5> Telefone </h5>
            <p>+55 11 992543050</p>
            <h5> E-mail </h5>
            <p>3ntransporte@gmail.com</p>
          </div>
          <div>
            <h4>Sede</h4>
  <div>
   <p>Rua Serra do mar <br /> 163, São Bernardo do Campo - SP <br />
   Brasil</p>
 
  </div>
          </div>
          <Div sx={{
            display: 'flex'
          }} mdxType="Div">
  {
              /* svg code: http://svgicons.sparkk.fr/ */
            }
  <SocialLink href={'https://m.facebook.com/3N-Transporte-108475364449344/?tsid=0.8349206348294979&source=result'} name={'icon'} iconPath={'M10,0.5c-5.247,0-9.5,4.253-9.5,9.5c0,5.247,4.253,9.5,9.5,9.5c5.247,0,9.5-4.253,9.5-9.5C19.5,4.753,15.247,0.5,10,0.5 M10,18.637c-4.77,0-8.636-3.867-8.636-8.637S5.23,1.364,10,1.364S18.637,5.23,18.637,10S14.77,18.637,10,18.637 M10.858,7.949c0-0.349,0.036-0.536,0.573-0.536h0.719v-1.3H11c-1.38,0-1.866,0.65-1.866,1.743v0.845h-0.86V10h0.86v3.887h1.723V10h1.149l0.152-1.299h-1.302L10.858,7.949z'} mdxType="SocialLink" />
  <SocialLink href={'https://www.instagram.com/3n_transportes/'} name={'icon'} iconPath={'M9.969,0.594c-5.195,0-9.406,4.211-9.406,9.406c0,5.195,4.211,9.406,9.406,9.406c5.195,0,9.406-4.211,9.406-9.406C19.375,4.805,15.164,0.594,9.969,0.594 M9.969,18.552c-4.723,0-8.551-3.829-8.551-8.552s3.829-8.551,8.551-8.551S18.521,5.277,18.521,10S14.691,18.552,9.969,18.552 M12.534,5.724H7.403c-0.944,0-1.71,0.766-1.71,1.71v5.131c0,0.944,0.766,1.71,1.71,1.71h5.131c0.944,0,1.71-0.766,1.71-1.71V7.435C14.244,6.49,13.479,5.724,12.534,5.724M11.679,7.007h1.283V8.29h-1.283V7.007z M9.969,8.29c0.944,0,1.71,0.766,1.71,1.71s-0.766,1.71-1.71,1.71s-1.71-0.766-1.71-1.71S9.024,8.29,9.969,8.29 M13.39,12.565c0,0.472-0.384,0.854-0.855,0.854H7.403c-0.472,0-0.855-0.383-0.855-0.854V9.573h0.898C7.423,9.712,7.403,9.854,7.403,10c0,1.417,1.149,2.565,2.565,2.565c1.416,0,2.565-1.148,2.565-2.565c0-0.146-0.02-0.288-0.043-0.427h0.898V12.565z'} mdxType="SocialLink" />
  <SocialLink href={'https://api.whatsapp.com/send?phone=5511992543050&text=Ol%C3%A1'} name={'icon'} iconPath={'M10,2.262c-3.486,0-6.322,2.837-6.322,6.322c0,2.129,1.105,4.126,2.905,5.291l0.009,3.396c0.002,0.168,0.093,0.326,0.24,0.406c0.072,0.041,0.149,0.061,0.228,0.061c0.086,0,0.171-0.023,0.246-0.07l6.338-3.922c0.037-0.021,0.069-0.049,0.098-0.08c1.618-1.193,2.581-3.084,2.581-5.082C16.322,5.099,13.485,2.262,10,2.262z M13.109,12.969c-0.016,0.01-0.03,0.023-0.044,0.037l-5.542,3.426l-0.006-2.594c0.012-0.027,0.023-0.057,0.03-0.086c0.05-0.203-0.041-0.414-0.221-0.52c-1.675-0.963-2.715-2.746-2.715-4.648c0-2.971,2.417-5.387,5.388-5.387c2.971,0,5.387,2.417,5.387,5.387C15.387,10.316,14.536,11.955,13.109,12.969z'} mdxType="SocialLink" />
          </Div>
        </Div>
        <Div sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          p: 3
        }} mdxType="Div">
  © {new Date().getFullYear()} <a href="https://cloura.dev">cloura.dev</a> && @ladydark
        </Div>
      </ScrollAnimation>
    </Div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "imagesFluid": "caracteristica6.jpg",
  "title": "Servico 6"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const FeatureLayout = makeShortcode("FeatureLayout");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <FeatureLayout width={'33%'} featureImageFluid={props.imagesFluid['caracteristica6.png']} mdxType="FeatureLayout">
 <h2>Transporte de Carga Dedicado </h2>
      <p>
Transporte totalmente exclusivo, podendo preencher o espaço total do veículo ou não. </p>
    </FeatureLayout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "aboutImage": "quemsomos.jpeg"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ContainerWrapper = makeShortcode("ContainerWrapper");
const ScrollAnimation = makeShortcode("ScrollAnimation");
const AboutLayout = makeShortcode("AboutLayout");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <ContainerWrapper sx={{
      background: theme => theme.colors['backgroundAbout']
    }} mdxType="ContainerWrapper">
      <ScrollAnimation animateIn="fadeIn" animateOnce mdxType="ScrollAnimation">
        <AboutLayout aboutImageFluid={props.imagesFluid['quemsomos.jpeg']} mdxType="AboutLayout">
  <h2>Quem somos</h2>
  <p>
  A 3N transportes foi fundada em 2012, tendo como principal objetivo oferecer eficiência e excelência na prestação de nossos serviços. Atuamos com profissionais capacitados, com mais de 20 anos de experiência no ramo de transportes.
  </p>
  <p>
  Temos uma das mais completas e rápidas logísticas terrestres do Brasil, junto com uma frota renovada, melhorando assim, nosso conceito de entregas rápidas.
  </p>
        </AboutLayout> 
      </ScrollAnimation>
    </ContainerWrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
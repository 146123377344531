import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Caracteristica1 from './caracteristica1.mdx';
import Caracteristica2 from './caracteristica2.mdx';
import Caracteristica3 from './caracteristica3.mdx';
import Caracteristica4 from './caracteristica4.mdx';
import Caracteristica5 from './caracteristica5.mdx';
import Caracteristica6 from './caracteristica6.mdx';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ContainerWrapper = makeShortcode("ContainerWrapper");
const ScrollAnimation = makeShortcode("ScrollAnimation");
const FeaturesWrapper = makeShortcode("FeaturesWrapper");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <ContainerWrapper sx={{
      background: theme => theme.colors['backgroundFeatures']
    }} mdxType="ContainerWrapper">
  <ScrollAnimation animateIn="fadeIn" mdxType="ScrollAnimation">
    <FeaturesWrapper mdxType="FeaturesWrapper">
      <Caracteristica1 {...props} mdxType="Caracteristica1" />
      <Caracteristica2 {...props} mdxType="Caracteristica2" />
      <Caracteristica3 {...props} mdxType="Caracteristica3" />
    </FeaturesWrapper>
  </ScrollAnimation>
    </ContainerWrapper>
    <ContainerWrapper sx={{
      background: theme => theme.colors['backgroundFeatures']
    }} mdxType="ContainerWrapper">
  <ScrollAnimation animateIn="fadeIn" mdxType="ScrollAnimation">
    <FeaturesWrapper mdxType="FeaturesWrapper">
      <Caracteristica4 {...props} mdxType="Caracteristica4" />
      <Caracteristica5 {...props} mdxType="Caracteristica5" />
      <Caracteristica6 {...props} mdxType="Caracteristica6" />
    </FeaturesWrapper>
  </ScrollAnimation>
    </ContainerWrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
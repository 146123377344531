import baseTheme from 'gatsby-theme-wordpress-mdx/src/gatsby-plugin-theme-ui'
export default {
  ...baseTheme,
  colors: {
    ...baseTheme.colors,
    modes: {
      dark: {
        textSecondary: '#FEFEFE',
        black: '#0a0a0a',
        text: '#FEFEFE',
        background: '#181834',
        primary: '#8CC6FF',
        muted: '#FEFEFE',
        backgroundNavBar: '#181834',
        backgroundSideBar: '#8CC6FF',
        activeTextBar: '#FEFEFE',
        textBar: '#FEFEFE',
        backgroundCard: '#FEFEFE',
        shadowCard: '9px 8px 50px rgba(32,32,35,.1)',
        imgShadow: '6px 7px 20px 0px rgb(43, 43, 47)'
      }
    }
  },
  fonts: {
    body: '"Ladasans", sans-serif',
    heading: 'SF Movie Poster, serif',
  },
  logo: {
    width: '10em'
  }
}

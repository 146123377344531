import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "imagesFluid": "caracteristica3.jpg",
  "title": "Servico 3"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const FeatureLayout = makeShortcode("FeatureLayout");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <FeatureLayout width={'33%'} featureImageFluid={props.imagesFluid['caracteristica3.png']} mdxType="FeatureLayout">
 <h2>Milk run</h2>
      <p>O conceito Milk Run, é realizado pelo roteiro de coletas programadas, a fim de melhorar o transporte de dois ou mais fornecedores de uma mesma região, até as dependências da fábrica. Com este método é possível programar datas, horários, e quantidades preestabelecidas, nivelando o fluxo diário de recebimento de matérias e reduzindo o número de veículos internos em sua fábrica. O gerenciamento das coletas e o planejamento de entregas, ficam por conta de nossa responsabilidade.</p></FeatureLayout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
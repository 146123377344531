import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "imagesFluid": "caracteristica1.jpg",
  "title": "Servico 1"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const FeatureLayout = makeShortcode("FeatureLayout");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <FeatureLayout width={'33%'} featureImageFluid={props.imagesFluid['caracteristica1.png']} mdxType="FeatureLayout">
 <h2>Transporte Emergencial</h2>
      <p>{`Com a finalidade de proporcionar atendimento imediato, em busca das melhores e mais ágeis soluções para as entregas de sua empresa.`}</p>
    </FeatureLayout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      